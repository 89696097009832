import React from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundKitchen";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col,
} from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Kitchen = () => {
    const data = useStaticQuery(graphql`
    query {
      wideDivider1: file(relativePath: { eq: "kitchen3.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      k1: file(relativePath: { eq: "kitchen1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      k2: file(relativePath: { eq: "kitchen2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      k3: file(relativePath: { eq: "kitchen5.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      
      b1: file(relativePath: { eq: "bath6.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b2: file(relativePath: { eq: "bath7.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b3: file(relativePath: { eq: "bath8.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b4: file(relativePath: { eq: "bath9.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b5: file(relativePath: { eq: "bath10.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b6: file(relativePath: { eq: "bath11.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      b7: file(relativePath: { eq: "bath12.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      
      paragraph1: file(relativePath: { eq: "bath5.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      paragraph2: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  
 return (
  <Layout>
    <SEO title="Kitchen and Bathroom Contractor" path='kitchen-and-bathroom-contractor'/>
    <StyledBackgroundSection/>
    <AboveFold
      title='Kitchen & Bath'
      >
    </AboveFold>
    
    <Hr thick/>
    
    <main id='main'>
      <div id='kitchen' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid id='cg-stakesBox'>
            <Row>
              <Col>
                <SectionHeading>Kitchen</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign:'left'}}>
                <p>
                  When it's time to upgrade your kitchen, we can help. Take a 
                  look at our past work, then contact us for a quote on your project.
                </p>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='Highly recommended!' name='Yuliya V.' thumbtack>
                    Mike is very reliable and gets the job done. Highly recommend for kitchen and bathroom work.
                  </Review>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.k1.childImageSharp.fluid} className="cg-picture" alt="kitchen photo"/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.k2.childImageSharp.fluid} className="cg-picture" alt="kitchen photo"/>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.k3.childImageSharp.fluid} className="cg-picture" alt="kitchen photo"/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/reviews#main'>Reviews</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/portfolio-kitchen'>More Photos</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      
      
      <div id='bathroom' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid id='cg-stakesBox'>
            <Row>
              <Col>
                <SectionHeading>Bathroom</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col style={{textAlign:'left'}}>
                <p>
                  However nice you want your bathroom, we can help you get it there. Have a 
                  look at our work below, then contact us for a quote.
                </p>
                <div style={{padding:'1rem', textAlign:'center'}}>
                  <Review title='Great Results' name='Alex'>
                    Great contractor. Always delivers great results. Remodeled my entire kitchen, floor to ceiling. Reasonable pricing. Highly recommend. 
                  </Review>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b1.childImageSharp.fluid} className="cg-picture cg-ratio-43" alt="bathroom photo"/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b2.childImageSharp.fluid} className="cg-picture cg-ratio-11" alt="bathroom photo"/>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b3.childImageSharp.fluid} className="cg-picture cg-ratio-11" alt="bathroom photo"/>
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b4.childImageSharp.fluid} className="cg-picture cg-ratio-43" alt="bathroom photo"/>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b5.childImageSharp.fluid} className="cg-picture cg-ratio-43" alt="bathroom photo"/>
                </div>
              </Col>
            </Row>
            
            
            <Row>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b6.childImageSharp.fluid} className="cg-picture cg-ratio-43" alt="bathroom photo"/>
                </div>
              </Col>
              <Col sm>
                <div style={{padding:'1rem'}}>
                  <Img fluid={data.b7.childImageSharp.fluid}  className="cg-picture cg-ratio-43" alt="bathroom photo"/>
                </div>
              </Col>
            </Row>
            
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/reviews#main'>Reviews</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Link className='btn btn-secondary' to='/portfolio-bathroom'>More Photos</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      
      
      <div id='guide2' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                  <Review title='Attention to detail' name='Vadim V.' thumbtack>
                    I had Mike come out to the house and we conversed about what I wanted (new kitchen, new bathroom, sheetrock work, new windows, tiles). Once we came up with a plan, Mike worked with me to help get my permits in place. Mike quoted us a fair and honest price and never wavered from it. Mike and his crew put the plan into motion. We were amazed at the attention to detail. In fact, we were so happy with the work they had done; we decided to have them redo the siding and installed a new garage door on our garage. These guys are the real deal. They are men of their word and they work their butts off to make things right. I wouldn't hesitate to recommend them to anyone I know. They will be my first phone call if I need any other renovations done in the future. Mike and his crew are hardworking, dependable, professional and courteous. Mike and his crew were friendly and easy to work with. He made every attempt to see that we were happy with our repairs.
                  </Review>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      
      
      <div id='paragraph' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we want you to feel good about every room in your house. 
                  We have a <Link to="/reviews#main" className='cg-linkDark'>track record</Link> that 
                  proves that you can get an amazing kitchen and luxurious bathroom. It's easy: 
                  1.Get a quote 2.We'll contact you to discuss project details 3.We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  So you can stop just thinking about updating your kitchen and bath and instead get it done and enjoy&nbsp;them.
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{maxWidth:200, marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <Button variant='secondary' href='/reviews#main'>Reviews</Button>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider1.childImageSharp.fluid} transparent />
      
    </main>
    
    <ContactForm/> 
    
        
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>Kitchen and bathroom photos are from MLB Contracting projects.</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    
  </Layout>
)};


export default Kitchen;
